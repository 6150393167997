import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";

import { useEffect } from "react";

import { ChakraProvider } from "@chakra-ui/provider";
import { createStandaloneToast, extendTheme } from "@chakra-ui/react";

import { Roboto } from "@next/font/google";
import { GTM_ID, pageview } from "../lib/googleTagManager";
import { ipcoTheme } from "../lib/ipcoTheme";

const roboto = Roboto({ weight: ["500", "700"] });

function MyApp({ Component, pageProps }: AppProps) {
  const theme = extendTheme(ipcoTheme);
  const router = useRouter();
  useEffect(() => {
    router.events.on("routeChangeComplete", pageview);
    return () => {
      router.events.off("routeChangeComplete", pageview);
    };
  }, [router.events]);
  const { ToastContainer } = createStandaloneToast();

  return (
    <>
      <Script
        id={"google-tag-manager"}
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${GTM_ID}');
                `,
        }}
      />
      <ChakraProvider theme={theme}>
        {/* eslint-disable-next-line react/no-unknown-property */}
        <style jsx global>{`
          html {
            font-family: ${roboto.style.fontFamily};
          }
        `}</style>
        <Component {...pageProps} />
        <ToastContainer />
      </ChakraProvider>
    </>
  );
}

export default MyApp;
