import { StepsStyleConfig as Steps } from "chakra-ui-steps";

export const ipcoTheme = {
  components: {
    Steps,
    Button: {
      variants: {
        solid: {
          // bgColor: "blueBrand.500",
          // color: "white"
        },
      },
    },
  },
  colors: {
    yellowBrand: {
      50: "#FCF7E9",
      100: "#F9EED4",
      200: "#F4DDA8",
      300: "#EECC7D",
      400: "#E9BB51",
      500: "#E3AA26",
      600: "#B6881E",
      700: "#886617",
      800: "#5B440F",
      900: "#2D2208",
    },
    blueBrand: {
      50: "#E6ECEF",
      100: "#CDD8DF",
      200: "#9BB1BF",
      300: "#698A9E",
      400: "#37637E",
      500: "#053C5E",
      600: "#04304B",
      700: "#032438",
      800: "#021826",
      900: "#010C13",
    },
    redBrand: {
      50: "#F5E8E8",
      100: "#EBD1D2",
      200: "#D6A3A4",
      300: "#C27677",
      400: "#AD4849",
      500: "#991A1C",
      600: "#7A1516",
      700: "#5C1011",
      800: "#3D0A0B",
      900: "#1F0506",
    },
    gray: {
      base: "#E5E5E5",
    },
    green: {
      500: "#5e8d62",
      700: "#224a59",
    },
  },
  styles: {
    global: {
      "html, body": {
        color: "gray.700",
        fontWeight: "400",
        lineHeight: "135%",
      },
      a: {
        color: "blueBrand.500",
        fontWeight: "bold",
      },
    },
  },
};
